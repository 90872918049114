import { Controller } from "@hotwired/stimulus"

class SignUpFormController extends Controller {
  static targets = ['confirmationRedirectUrl'];

  connect() {
    const url = localStorage.getItem('confirmationRedirectUrl') || location.href;

    localStorage.setItem('confirmationRedirectUrl', url);

    if(url.includes('/users')) {
      return;
    }

    this.confirmationRedirectUrlTarget.value = url;
  }
}

export default SignUpFormController;
