import { Controller } from "@hotwired/stimulus"

class SendConfirmationFormController extends Controller {
  static targets = ['confirmationInput', 'confirmationRedirectUrl']

  connect() {
    const parentModal = document.querySelector('#account_confirmation_modal');

    parentModal.addEventListener('neo_modal:success', () => { 
      Helper.flash_message("success", "The email has been sent");
    });

    this.confirmationRedirectUrlTarget.value = localStorage.getItem('confirmationRedirectUrl');
  }
}

export default SendConfirmationFormController;
